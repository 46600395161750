/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.commit-container, .alert {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.responsive .react-colorful {
  width: auto;
  margin-bottom: 1rem;
  height: 300px;
}

.number {
  font-size: 50vw;
}

a:hover {
  color: #cbc8c8;
}